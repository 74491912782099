@import "./media-library-pro.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: proximaNova, sans-serif, system-ui;
  }
}

#__next {
  min-height: 100%;
}
@font-face {
  font-family: 'proxima-nova-thin';
  src: url('../../../public/fonts/proxima-nova-thin.otf');
}
@font-face {
  font-family: 'proxima-nova-regular';
  src: url('../../../public/fonts/proxima-nova-regular.otf');
}
@font-face {
    font-family: 'proxima-nova-semibold';
    src: url('../../../public/fonts/proxima-nova-semibold.otf');
}
@font-face {
  font-family: 'proxima-nova-bold';
  src: url('../../../public/fonts/proxima-nova-bold.otf');
}
@font-face {
  font-family: 'proxima-nova-extrabold';
  src: url('../../../public/fonts/proxima-nova-extrabold.otf');
}
@font-face {
  font-family: 'proxima-nova-black';
  src: url('../../../public/fonts/proxima-nova-black.otf');
}
@font-face {
  font-family: 'proxima-nova-alt-thin';
  src: url('../../../public/fonts/proxima-nova-alt-thin.otf');
}
@font-face {
  font-family: 'proxima-nova-alt-alt-light';
  src: url('../../../public/fonts/proxima-nova-alt-light.otf');
}
@font-face {
  font-family: 'proxima-nova-alt-bold';
  src: url('../../../public/fonts/proxima-nova-alt-bold.otf');
}

/* removes blue tailwind focus ring */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
}
.ring-1 {
  --tw-ring-shadow: 0 0 0 1px rgba(246, 162, 59, 0.295);
}

.tox-toolbar__button:hover {
    background-color: orange;
    color: white;
}
